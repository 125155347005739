export default defineNuxtRouteMiddleware((to) => {
  const { $pinia } = useNuxtApp();
  const { user } = storeToRefs(useUserStore($pinia));

  const intendedDestination = useCookie("intendedDestination");

  if (!user.value && to.path !== "/account/login") {
    intendedDestination.value = to.fullPath;

    return navigateTo("/account/login");
  }
});
